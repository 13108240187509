<script>
import Vue from "vue";
import BaseVue from "@frontend/Base.vue";
import Gen from "../helper/Gen";
import { UserIcon, CalendarIcon } from "vue-feather-icons";

Vue.component("HomeSlider", () => import("@frontend/part/HomeSlider.vue"));

export default {
  extends: BaseVue,
  data() {
    return {
      static1_title: "",
      static1_postTitle: "",
      static1_desc: "",
      static1_img: "",
      static1_alt_img: "",
      static1_show: "",
      static2_title: "",
      static2_desc: "",
      static2_show: "",
      prestasi: [],
      nilai: [],
      berita: [],
      static3_title: "",
      static3_desc: "",
      static3_img1: "",
      static3_img2: "",
      static3_img3: "",
      static3_img4: "",
      static3_show: "",
      static3_alt_img1: "",
      static3_alt_img2: "",
      static3_alt_img3: "",
      static3_alt_img4: "",
      fasilitas: [],
      testimoni: [],
      article: [],
      showAbout: ''
    };
  },
  computed: {},
  components: {
    UserIcon,
    CalendarIcon,
  },
  watch: {
    $route() {
      setTimeout(()=>{
        $(".short-about__desc p a").addClass("linkWhite")
      },500)
      setTimeout(() => {
        window.SEMICOLON.widget.carousel();
        $(document).ready(function (){
          document.querySelector("#testimonyCarou").owlCarousel({
          margin: 48,
          loop: true,
          nav: true,
          navText: [
            '<i class="icon-angle-left"></i>',
            '<i class="icon-angle-right"></i>',
          ],
          autoplay: true,
          autoplayTimeout: 5000,
          dots: true,
          items: 1,
          responsive: {
            0: {
              stagePadding: 0,
            },
            992: {
              stagePadding: 240,
            },
            1200: {
              stagePadding: 300,
            },
          },
        });
        });
      }, 2000);
    },
  },
  async mounted() {
    this.apiGetSliderHome();
    setTimeout(()=>{
      $(".short-about__desc p a").addClass("linkWhite")
    },500)
    this.$set(this.$root, "page", this);
    setTimeout(() => {
      window.SEMICOLON.widget.carousel();
      SEMICOLON.slider.init();
      $("#testimonyCarousel").owlCarousel({
        margin: 48,
        loop: true,
        nav: true,
        navText: [
          '<i class="icon-angle-left"></i>',
          '<i class="icon-angle-right"></i>',
        ],
        autoplay: true,
        autoplayTimeout: 5000,
        dots: true,
        items: 1,
        responsive: {
          0: {
            stagePadding: 0,
          },
          992: {
            stagePadding: 240,
          },
          1200: {
            stagePadding: 300,
          },
        },
      });
    }, 3000);
  },
  methods: {
    apiGetSliderHome() {
      Gen.apirest("/home-static-setting", {}, (err, resp) => {
        if (err) console.log(err);
        this.static1_title = resp.about.static1_title;
        this.static1_postTitle = resp.about.static1_postTitle;
        this.static1_desc = resp.about.static1_desc;
        this.static1_img = resp.about.static1_img;
        this.static1_show = resp.about.static1_show;
        this.static1_alt_img = resp.about.static1_alt_img;
        this.static2_title = resp.prestasi.static2_title;
        this.static2_desc = resp.prestasi.static2_desc;
        this.static2_show = resp.prestasi.static2_show;
        this.static2_alt_img = resp.prestasi.static2_alt_img;
        this.prestasi = resp.prestasi;
        this.nilai = resp.nilai;
        this.static3_title = resp.kurikulum.static3_title;
        this.static3_desc = resp.kurikulum.static3_desc;
        this.static3_img1 = resp.kurikulum.static3_img1;
        this.static3_img2 = resp.kurikulum.static3_img2;
        this.static3_img3 = resp.kurikulum.static3_img3;
        this.static3_img4 = resp.kurikulum.static3_img4;
        this.static3_alt_img1 = resp.kurikulum.static3_alt_img1;
        this.static3_alt_img2 = resp.kurikulum.static3_alt_img2;
        this.static3_alt_img3 = resp.kurikulum.static3_alt_img3;
        this.static3_alt_img4 = resp.kurikulum.static3_alt_img4;
        this.static3_show = resp.kurikulum.static3_show;
        this.static3_alt_img = resp.kurikulum.static3_alt_img;
        this.fasilitas = resp.fasilitas;
        this.testimoni = resp.testimoni;
        this.berita = resp.berita;
        for (let i = 0; i < this.berita.data.length; i++) {
          this.berita.data[i].an_publish_date = this.dateStrFormat(this.berita.data[i].an_publish_date);
        }
      });
    },
  },
  watch: {
    $route() {
      setTimeout(() => {
        SEMICOLON.header.init();
      }, 500);
    },
  },
};
</script>

<template>
  <div>
    <HomeSlider />
    <section id="content"> 
      <div class="content-wrap">
        <section class="section bg-color dark py-49" v-if="static1_show === 'Y'">
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 col-md-10 order-2 order-lg-0">
                <div class="short-about">
                  <div class="short-about__title">
                    <h2 itemprop="alternativeHeadline">{{static1_title}}</h2>
                    <span itemprop="text" class="short-about__title__semi">{{static1_postTitle}}</span>
                  </div>
                  <div itemprop="description" class="short-about__desc" v-html="static1_desc"></div>
                </div>
                <div class="button-center">
                  <router-link :to="{ name: 'About' }" class="cta cta--primary"
                    >Selengkapnya</router-link
                  >
                </div>
              </div>
              <div class="col-lg-6 col-md-10">
                <img itemprop="image" :src="uploader(static1_img)" class="short-about__img" :alt="static1_alt_img" :title="static1_alt_img" />
              </div>
            </div>
          </div>
        </section>
        <section class="section green-wave py-120" v-if="static2_show === 'Y'">
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 col-md-10 order-3 order-lg-0 mt-4 mt-lg-0">
                <div class="our-achievement">
                  <div class="heading-block border-0 mb-sm">
                    <h2
                      class="our-achievement__title text__center"
                      v-html="static2_title"
                      itemprop="alternativeHeadline"
                    ></h2>
                  </div>
                  <p itemprop="description" class="our-achievement__desc" v-html="static2_desc"></p> 
                  <div class="button-center">
                    <router-link :to="{ name: 'About' }" class="cta cta--primary"
                      >Pelajari Selengkapnya</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-10">
                <div
                  class="owl-carousel carousel-widget achievement-carousel"
                  data-items="1"
                  data-autoplay="5000"
                  data-nav="true"
                  data-pagi="true"
                  data-loop="true"
                >
                  <div class="box-media" v-for="(v2, k2) in prestasi.data" :key="k2">
                    <img
                      :src="uploader(v2.ap_img)"
                      class="box-media__img"
                      :alt="v2.ap_alt_img"
                      :title="v2.ap_name"
                      itemprop="photo"
                    />
                    <h3 class="box-media__title" v-html="v2.ap_name"></h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section light-bg py-64" v-if="nilai.show === 'Y'">
          <div class="container">
            <div class="heading-block center border-0">
              <h2>Nilai - Nilai Kami</h2>
            </div>
            <div class="row col-mb-48">
              <div class="col-md-6" v-for="(v3, k3) in nilai.data" :key="k3">
                <div class="card-elem">
                  <div class="values">
                    <div :class="'value-circle ' + v3.an_bg">
                      <div class="value-circle__content">
                        <img
                          :src="uploader(v3.an_img)"
                          :alt="v3.an_title"
                          :title="v3.an_alt_img"
                          class="value-circle__img"
                          itemprop="image"
                        />
                      </div>
                    </div>
                    <div class="value-content">
                      <h3
                        class="value-content__title"
                        v-html="v3.an_title"
                        itemprop="name"
                      ></h3>
                      <p itemprop="description" class="value-content__desc" v-html="v3.an_desc"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section bg-color dark py-75" v-if="static3_show === 'Y'">
          <div class="container">
            <div class="row justify-content-center align-items-center">
              <div class="col-md-10 col-lg-6 order-3 order-lg-0 mt-4 mt-lg-0">
                <div class="islamic-explanation">
                  <div class="heading-block border-0 mb-sm">
                    <h2 itemprop="alternativeHeadline" class="islamic-explanation__title" v-html="static3_title"></h2>
                  </div>

                  <div itemprop="description" v-html="static3_desc" style="color: #fff"></div>
                </div>
                <div class="button-center">
                  <router-link :to="{ name: 'Curriculum' }" class="cta cta--primary"
                    >Selengkapnya</router-link
                  >
                </div>
              </div>
              <div class="col-md-10 col-lg-6">
                <div class="islamic-explanation-media-group">
                  <div class="row gutter-16">
                    <div class="col-6">
                      <div class="box-media">
                        <img
                          :src="uploader(static3_img1)"
                          :alt="static3_alt_img1"
                          :title="static3_alt_img1"
                          class="box-media__img"
                          itemprop="photo"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="box-media">
                        <img
                          :src="uploader(static3_img2)"
                          :alt="static3_alt_img2"
                          :title="static3_alt_img2"
                          class="box-media__img"
                          itemprop="photo"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="box-media">
                        <img
                          :src="uploader(static3_img3)"
                          :alt="static3_alt_img3"
                          :title="static3_alt_img3"
                          class="box-media__img"
                          itemprop="photo"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="box-media">
                        <img
                          :src="uploader(static3_img4)"
                          :alt="static3_alt_img4"
                          :title="static3_alt_img4"
                          class="box-media__img"
                          itemprop="photo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section green-wave py-114" v-if="fasilitas.show === 'Y'">
          <div class="container">
            <div class="heading-block center border-0">
              <h2 itemprop="alternativeHeadline">Fasilitas Sekolah</h2>
            </div>
            <div
              class="owl-carousel carousel-widget facility-carousel fasilitas"
              data-items-xs="1"
              data-items-md="2"
              data-items-lg="3"
              data-items-xl="3"
              :data-loop="fasilitas.data.length <= 3 ? 'false' : 'true'"
              data-autoplay="5000"
              data-nav="true"
              data-pagi="true"
            >
              <div class="box-media" v-for="(v4, k4) in fasilitas.data" :key="k4">
                <img
                  :src="uploader(v4.ap_img)"
                  :alt="v4.ap_name"
                  :title="v4.ap_alt_img"
                  class="box-media__img w-100"
                  itemprop="photo"
                />
                <h3 itemprop="name" class="box-media__title" v-html="v4.ap_name"></h3>
              </div>
            </div>
          </div>
        </section>

        <section class="section bg-color py-54" v-if="(testimoni.data || []).length > 0 && testimoni.show === 'Y'">
          <!-- Noted. dimakesure dulu sama kak Mega -->
          <div class="container overflow-hidden">
            <div class="heading-block center border-0">
              <h2 itemprop="alternativeHeadline">Kata Orang Tua Murid</h2>
            </div>
            <div
              class="owl-carousel product-carousel carousel-widget testimony-carousel customjs"
              id="testimonyCarousel"
            >
              <div class="card-elem" v-for="(v5, k5) in testimoni.data" :key="k5">
                <div class="parent-testimony" itemprop="review" itemscope itemtype="https://schema.org/Review">
                  <img
                    v-if="v5.at_photo"
                    :src="uploader(v5.at_photo)"
                    :alt="v5.at_alt_photo"
                    :title="v5.at_occupation"
                    class="parent-testimony__img"
                    itemprop="image"
                  />
                  <img
                    v-if="!v5.at_photo"
                    :src="assets('fo_images', 'avatar.jpg')"
                    :alt="v5.at_alt_photo"
                    :title="v5.at_occupation"
                    class="parent-testimony__img"
                    itemprop="image"
                  />
                  <div class="parent-testimony-meta">
                    <h3
                      class="parent-testimony__name"
                      v-html="v5.at_fullname"
                      itemprop="author"
                    ></h3>
                    <span
                      class="parent-testimony__job"
                      v-html="v5.at_occupation"
                      itemprop="name"
                    ></span>
                    <p
                      class="parent-testimony__desc"
                      v-html="v5.at_testimoni"
                      itemprop="reviewBody"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section bg-white py-54 py-article" v-if="(berita.data || []).length > 0 && berita.show === 'Y'">
          <div class="container" itemscope itemtype="https://schema.org/NewsArticle">
            <div class="heading-block center border-0">
              <h2 itemprop="alternativeHeadline">Berita dan Artikel Terbaru</h2>
            </div>
            <div class="row justify-content-center col-mb-48">
              <div
                class="col-md-6 col-lg-4"
                v-for="(v6, k6) in berita.data"
                :key="k6"
              >
                <article class="article">
                  <figure class="article-media">
                    <router-link
                      :to="{
                        name: 'NewsArticleDetail',
                        params: { slug: v6.an_slug },
                      }"
                      ><img
                        :src="uploader(v6.an_img)"
                        :alt="v6.an_alt_img"
                        :title="v6.an_alt_img"
                        class="article__img"
                        itemprop="thumbnailUrl"
                    /></router-link>
                    <div class="article-media-badges">
                      <router-link
                        :to="{
                          name: 'NewsArticleCategory',
                          params: { tipe: 'artikel' },
                        }"
                        v-if="v6.anc_type == '1'"
                        class="article-badge color-2"
                        >Article</router-link
                      >
                      <router-link
                        :to="{
                          name: 'NewsArticleCategory',
                          params: { tipe: 'berita' },
                        }"
                        v-else-if="v6.anc_type == '2'"
                        class="article-badge"
                        >Berita</router-link
                      >
                      <router-link
                        :to="{
                          name: 'NewsArticleCategory',
                          params: { tipe: v6.anc_type == '1' ? 'artikel' : 'berita', kategori: v6.anc_slug },
                        }"
                        :class="[
                          'article-badge',
                          v6.anc_type == '1' ? 'color-2' : '',
                        ]"
                        >{{ v6.anc_category }}</router-link
                      >
                    </div>
                  </figure>
                  <figcaption class="article-meta">
                    <h3 itemprop="name" class="article__title">
                      <router-link
                        :to="{
                          name: 'NewsArticleDetail',
                          params: { slug: v6.an_slug },
                        }"
                        >{{ v6.an_title }}</router-link
                      >
                    </h3>
                    <p itemprop="description" class="article__shortdesc">
                      <router-link
                        :to="{
                          name: 'NewsArticleDetail',
                          params: { slug: v6.an_slug },
                        }"
                        >{{ v6.an_desc.limitWord(14) }}</router-link
                      >
                    </p>
                    <div class="article-meta-auth">
                      <span itemprop="author" class="article-meta-auth__info d-flex">
                        <div>
                          <user-icon></user-icon> 
                        </div>
                        <div>
                          {{ v6.an_author }}
                        </div>
                      </span>
                      <span itemprop="datePublished" class="article-meta-auth__info d-flex">
                        <div>
                          <calendar-icon></calendar-icon>
                        </div>
                        <div>
                          {{ v6.an_publish_date }}
                        </div>
                      </span>
                    </div>
                  </figcaption>
                </article>
              </div>
            </div>
            <div class="text-center mt-5">
              <router-link
                :to="{ name: 'NewsArticle' }"
                class="cta cta--primary"
                >Selengkapnya</router-link
              >
            </div>
          </div>
        </section>
      </div>
    </section>

    <section class="section green-wave text-center" 
      v-if="
      static1_show === 'N' && 
      static2_show === 'N' && 
      static3_show === 'N' && 
      nilai.show === 'N' && 
      fasilitas.show === 'N' && 
      testimoni.show === 'N' && 
      berita.show === 'N'
    ">
      <h3 class="fw-bold">Data belum tersedia</h3>
    </section>
    <!-- #content end -->
  </div>
</template>
